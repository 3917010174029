import User from '../../user';

export class UserRegisterDto {
  authorized = false;
  firstName: string;
  lastName: string;
  secondName: string;
  readonly email;
  phone: string;
  password = '';
  inn = '';
  ogrn = '';
  companyEmail = '';
  fullName = '';
  shortName = '';
  apiKey = '';
  user = '';


  constructor(currentUser: User) {
    this.lastName = currentUser.LAST_NAME;
    this.firstName = currentUser.NAME;
    this.secondName = currentUser.SECOND_NAME;
    this.email = currentUser.EMAIL;
    this.phone = currentUser.PERSONAL_MOBILE || currentUser.WORK_PHONE || '';
  }
}