import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ApiError } from '../../models/interfaces/common';

export function useErrorHandler() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const defaultPrefix = "error.httpError.";

  return useCallback((name: string, error: any) => {
    error = error?.response ? error.response : error;
    if (error.data?.errorKey) {
      enqueueSnackbar(
        t(`error.${error.data.errorKey}`),
        { variant: "error" }
      );
      return  error.data.errors ?? null;
    }
    if (error.data?.data?.errors?.length) {
      const apiErrors = error.data.data.errors.reduce((acc: Record<string, string>, err: ApiError) => { acc[err.code] = t(`error.api.${err.code}`,  { nsSeparator: '|' }); return acc; }, {});
      const showUp: ApiError[] = error.data.data.errors.filter((err: ApiError) => !err.code.includes('VLDN'));
      if (showUp.length) {
        enqueueSnackbar(
          <div dangerouslySetInnerHTML={{ __html: showUp.map((err) => apiErrors[err.code]).join('<br/>') }} />,
          { variant: "error" }
        );
      }
      return apiErrors;
    }

    if (error && error.status === 400) {
      console.error(name, "validation error", error.data);
      enqueueSnackbar(
        t(`${defaultPrefix}badRequest`),
        { variant: "error" }
      );
      return;
    }

    if (error && error.status === 401) {
      console.error(name, "authorize error");
      enqueueSnackbar(
        t(`${defaultPrefix}unauthorized`),
        { variant: "error" }
      );
      return;
    }

    if (error && error.status === 403) {
      console.error(name, "access error");
      enqueueSnackbar(
        t(`${defaultPrefix}accessDenied`),
        { variant: "error" }
      );
      return;
    }

    if (error && error.status === 404) {
      console.error(name, "not found error");
      enqueueSnackbar(
        t(`${defaultPrefix}notFound`),
        { variant: "error" }
      );
      return;
    }

    if (error && error.status === 500) {
      console.error(name, error);
      enqueueSnackbar(
        t(`${defaultPrefix}internal`),
        { variant: "error" }
      );
      return;
    }
    console.error(name, error);
  }, [t, enqueueSnackbar]);
}
