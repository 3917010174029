import React from "react";
import { SelectItem } from '../models/interfaces';
import { AccessKey } from '../enum/accessKey';
import { SettingsEnum } from '../models/enum';
import { SettingsItem } from '../models/domain';

const defaultContext = {
    appVersion: 0,
    settings: {} as Record<SettingsEnum, SettingsItem<any>>,
    isApiKeyLegal: false,
    setAppSettings: async (key: SettingsEnum, data: any, keyAuth?: SelectItem[]): Promise<boolean> => { return true; },
    updateComplete: () => { },
    access: (key: AccessKey) => false,
    setHeaderVisibility: (state: boolean) => { },
    setPDConsent: () => { },
    featureFlag: {} as Record<string, boolean>,
    currentClient: null as string|null,
    setCurrentClient: (val: string) => {},
    apiClients: [] as SelectItem[]|null,
    availableClients: [] as SelectItem[],
    toggleApiInfoDialog: (val: boolean) => {},
};

export const AppContext = React.createContext(defaultContext);
