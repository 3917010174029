import { CustomContentProps, useSnackbar } from 'notistack';
import { forwardRef } from 'react';
import { Alert } from '@mui/material';

declare module 'notistack' {
  interface VariantOverrides {
    // removes the `default` variant
    default: false;
  }
}

interface IProps extends CustomContentProps {
  message: string;
}

export const CustomAlert = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { message, variant } = props;
  const { closeSnackbar } = useSnackbar();

  return (
    <Alert ref={ref} role="alert" severity={variant} sx={{ minWidth: '100%' }} onClose={() => closeSnackbar()}>
      {message}
    </Alert>
  );
});
