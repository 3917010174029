import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {useValidation} from "../../../hook/UseValidation";
import ValidationContext from "../../../hook/UseValidation/validation.context";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InputControlProps } from '../input.control.props';
import { SelectItem } from '../../../models/interfaces';

interface IProps extends InputControlProps<string|number>, Omit<RadioGroupProps, 'onChange' | 'value'> {
   items: SelectItem[]
}

export default function RadioControl(props: IProps) {
   const {t} = useTranslation();
   const validate = useValidation(props.validators)
   const {validateOn} = useContext(ValidationContext)
   const [errorText, setErrorText] = useState<string>();

   useEffect(() => {
      setErrorText(props.error || validate(props.value, props.tab ?? 'main'))
   }, [props.value, props.error, validateOn])

   return (
         <FormControl  required={props.required} error={!!errorText} fullWidth size="small">
            <FormLabel >{t(props.labelKey)}</FormLabel>
            <RadioGroup
              row={props.row}
               onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  props.onChange(event.target.value);
               }}>
               {props.items.map((item, idx) =>
                  <FormControlLabel key={idx} value={item.value} control={<Radio size="small" disabled={props.disabled} />} label={item.text} checked={item.value === props.value} sx={{ mr: props.row ? undefined : 'auto'}} />)}
            </RadioGroup>
            <FormHelperText>{errorText}</FormHelperText>
         </FormControl>
   )
}