export class EntityRoutes {
  readonly _base: string;
  protected identifier: string


  constructor(base: string, identifier = 'uuid') {
    this._base = base;
    this.identifier = identifier;
  }

  get base() {
    return `/${this._base}`;
  }

  list(specific = '') {
    return `${this.base}/list${specific}`;
  }

  details(identifier?: string) {
    return [this.base, identifier ?? `:${this.identifier}`, 'details'].join('/');
  }

  edit(identifier?: string) {
    return [this.base, identifier ?? ':uuid', 'edit'].join('/');
  }

  version(identifier?: string) {
    return [this.base, identifier ?? ':uuid', 'version'].join('/');
  }

  create() {
    return `${this.base}/create`;
  }
}