import { createTheme } from '@mui/material';
import { ruRU } from '@mui/material/locale';
import { ruRU as dpRu } from '@mui/x-date-pickers/locales/ruRU';

export const theme = createTheme(
  {
    typography: {
      allVariants: {
        color: '#4f4f4f',
      },
    },
    components: {
      MuiListItemIcon: {
        defaultProps: {
          sx: {
            // minWidth: '45px',
          },
        },
      },
    },
  },
  ruRU,
  dpRu,
);
