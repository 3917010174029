import { useTranslation } from "react-i18next";
import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import React, { useCallback } from 'react';
import { useErrorHandler } from '../../hook/useErrorHandler';
import { useSnackbar } from 'notistack';
import { UserService } from '../../service/user.service';
import { useCurrentUser } from '../../hook/useCurrentUser';

export function Welcome() {
  const { t } = useTranslation();
  const errorHandler = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();

  const resendEmail = useCallback(async () => {
    try {
      await UserService.resendEmail();
      enqueueSnackbar({
        message: t('user.welcome.resended', [currentUser?.EMAIL]),
        variant: 'success',
      })
    } catch(e) {
      errorHandler('resend email', e);
    }
  }, [t, errorHandler, enqueueSnackbar, currentUser]);

  return (
    <Box>
      <Typography variant="h5">{t("user.welcome.title")}</Typography>
      <br/>
      <Typography>
        {t("user.welcome.apiKey")}
        &nbsp;
        <Link component={RouterLink} to="/settings">
          {t("user.welcome.button.settings")}
        </Link>
        .
      </Typography>
      <br/>
      <Typography>
        {t("user.welcome.repeatEmail")}
        &nbsp;
        <Link component="button" onClick={() => resendEmail()}>{t("user.welcome.button.reemail")}</Link>.
      </Typography>
      <br/>
      <Typography>
        {t("user.welcome.repeatRegister")}
        &nbsp;
        <Link component={RouterLink} to="/register">
          {t("user.welcome.button.reregister")}
        </Link>
        .
      </Typography>
      <br/>
      <Typography variant="body2">
        {t("user.welcome.help")}
        &nbsp;
        <Link href={`mailto:${t('user.welcome.signxContact')}`}>{t("user.welcome.signxContact")}</Link>.
      </Typography>
    </Box>
  );
}
