import React, { ChangeEvent, useContext, useState } from 'react';
import { AppContext } from '../../context/app.context';
import { useQuery } from 'react-query';
import { Rest } from '../../rest';
import { useErrorHandler } from '../../hook/useErrorHandler';
import { SettingsService } from '../../service/settings.service';
import { SettingsEnum, WebhookEventType } from '../../models/enum';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import UserSelect from '../../component/select/user';
import i18next from 'i18next';
import ApikeyAuthorization from '../../component/apikeyAuthorization';
import { RobotAdding } from '../../component/robotAdding';
import DomainAuthorization from '../../component/domainAuthorization';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../hook/useCurrentUser';

export function Settings() {
  const context = useContext(AppContext);
  const handleError = useErrorHandler();
  const { t } = useTranslation();
  const currentUser = useCurrentUser();

  const { data: admins, refetch } = useQuery(
    "AdminList",
    async () => {
      const users = await Rest.getUsers(context.settings.AdminList.value as string[]);
      const admins: { value: number; label: string }[] = [];

      context.settings.AdminList.value.forEach((id: string) => {
        if (users[id]) {
          admins.push({ value: users[id].Id, label: users[id].FullName });
        }
      });

      return admins;
    },
    {
      onError: (error) => handleError("User list", error),
    }
  );
  const [notifySigner, setNotifySigner] = useState(context.settings.NotifySigner.value === 'Y');

  const onAdminsChange = async (selected: { value: number, label: string }[]) => {
    const adminIds = selected.map(x => x.value);

    if (adminIds.length > 0 && !adminIds.includes(currentUser!.Id)) {
      adminIds.push(currentUser!.Id);
    }
    await context.setAppSettings(SettingsEnum.AdminList, adminIds);
    return refetch();
  }

  const onChangeNotifySigner = async (e: ChangeEvent<HTMLInputElement>) => {
    setNotifySigner(e.target.checked);
    await context.setAppSettings(SettingsEnum.NotifySigner, e.target.checked ? 'Y' : 'N');
    await SettingsService.toggleWebhook(e.target.checked, WebhookEventType.SIGNER_NOTIFICATION);
  }

  return (
    <Grid container mt={3} columnSpacing={2}>
      <Grid item xs={8} sx={{ maxWidth: '650px' }}>
        <Grid container mt={3}>
          <Grid item xs={12}>
            <Box>
              <UserSelect
                value={admins}
                placeholder={i18next.t("field-administrators")}
                onChange={onAdminsChange}
                isMulti={true}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container mt={3}>
          <Grid item xs={12}>
            <ApikeyAuthorization />
          </Grid>
        </Grid>
        <Grid container mt={3}>
          <Grid item xs={12}>
            {context.settings.ExternalSystemClientId.value && <FormControlLabel control={<Checkbox
              checked={notifySigner}
              onChange={(e) => onChangeNotifySigner(e)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label={t('field-notify-signer')}/>}
          </Grid>
        </Grid>
        {context.settings.ExternalSystemClientId.value && <RobotAdding />}
      </Grid>
      <Grid item xs={4} sx={{ flexGrow: 1 }}>
          <Grid container mt={3}>
            <Grid item xs={12} lg={8} xl={7} >
              <DomainAuthorization />
            </Grid>
          </Grid>
        </Grid>,
    </Grid>
  )
}