import { Pagination } from '@mui/material';
import {
  GridPagination,
  gridPaginationModelSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { useMemo } from 'react';

export function TablePagination() {
  const apiRef = useGridApiContext();
  const pagination = useGridSelector(apiRef, gridPaginationModelSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageCount = useMemo(() => Math.ceil(rowCount / pagination.pageSize), [rowCount, pagination.pageSize]);

  return (
    <>
      {pageCount > 1 && (
        <Pagination
          count={pageCount}
          page={pagination.page + 1}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
          siblingCount={1}
          boundaryCount={1}
          shape="rounded"
          variant="outlined"
        />
      )}
      <GridPagination
        count={rowCount}
        onPageChange={(event, value) => apiRef.current.setPage(value)}
        page={pagination.page}
        rowsPerPage={pagination.pageSize}
        labelDisplayedRows={() => ''}
        className="ms-auto"
        nextIconButtonProps={{ sx: { display: 'none' } }}
        backIconButtonProps={{ sx: { display: 'none' } }}
        sx={{
          '& .MuiInputBase-root': { marginRight: 0 },
          '& .MuiTablePagination-actions': { marginLeft: 0 },
          marginLeft: 'auto',
        }}
      />
    </>
  );
}
