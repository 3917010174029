import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { InputControlProps } from '../input.control.props';
import { useTranslation } from 'react-i18next';

export default function CheckboxControl(props: InputControlProps<boolean>) {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <FormControlLabel
        control={
          <Checkbox
            checked={props.value}
            disabled={props.disabled}
            onChange={(event) => props.onChange(event.target.checked)}
          />
        }
        label={t(props.labelKey)}
        sx={{ mr: 'auto' }}
      />
    </FormControl>
  );
}
