import ValidationRule from '../validation.rule.enum';
import IValidator from './validator';
import validator from 'validator';
import isMobilePhone = validator.isMobilePhone;

export class IsPhoneValidator implements IValidator<string> {
  rule = ValidationRule.IsPhone;

  validate(value: string) {
    return (!value || isMobilePhone(value, 'ru-RU')) ? null : (['validation.phone'] as [string, ...any[]]);
  }
}
