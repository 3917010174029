import ModelValidator from './model.validator';
import { UserRegisterDto } from '../../models/dto/user';
import validator from 'validator';
import isEmail = validator.isEmail;
import isStrongPassword = validator.isStrongPassword;
import isMobilePhone = validator.isMobilePhone;

class CDocumentEditValidator extends ModelValidator<UserRegisterDto> {
  validate(model: UserRegisterDto, step: number, apiUser: boolean): string[] {
    const errors: string[] = [];
    this.checkRequired(model.lastName, errors, 'lastName');
    this.checkLength(model.lastName, errors, 255, 'lastName');
    this.checkRequired(model.firstName, errors, 'firstName');
    this.checkLength(model.firstName, errors, 255, 'firstName');
    this.checkLength(model.secondName, errors, 255, 'secondName');

    this.checkRequired(model.email, errors, 'email');
    this.check(model.email, errors, isEmail);

    this.checkLength(model.phone, errors, 255, 'phone');
    if (model.phone) {
      this.check(model.phone, errors, (val) => isMobilePhone(val, 'ru-RU'));
    }

    if (!apiUser) {
      this.checkRequired(model.password, errors, 'password');
      this.checkLength(model.password, errors, 255);
      this.check(model.password, errors, isStrongPassword, 'password');
    }

    if (step > (apiUser ? 1 : 0)) {
      this.checkRequired(model.inn, errors, 'inn');
      this.checkLength(model.inn, errors, 12, 'inn', 10);
      this.checkRequired(model.ogrn, errors, 'ogrn');
      this.checkLength(model.ogrn, errors, 15, 'ogrn', 13);
      this.checkRequired(model.companyEmail, errors, 'companyEmail');
      this.check(model.companyEmail, errors, isEmail);
      if (model.inn?.length === 10) {
        this.checkRequired(model.fullName, errors, 'fullName');
      }
      this.checkLength(model.fullName, errors, 255);
      this.checkLength(model.shortName, errors, 255);
    }

    return errors;
  }

}

export const UserRegisterValidator = new CDocumentEditValidator();
