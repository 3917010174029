class CErrorHandler {
    handle(name: string, error: any, showToast = true): string {
        error = error?.response ? error.response : error;
        if (error && error.data?.status === 400) {
            if (error.data.errors) {
                console.log(name, 'validation error', error.data.errors);
                // ToastManager.error({ message: 'main:validation-error-message', title: 'main:validation-error-title' });
                return 'main:validation-error-message';
            }
            console.log(name, 'bad request', error);
            // ToastManager.error({ message: 'main:bad-request-error-message', title: 'main:bad-request-error-title' });
            return 'main:bad-request-error-message';
        }

        if (error && error.status === 401) {
            console.error(name, 'access error');
            if (error.ex && error.ex.error === 'insufficient_scope') {
                // ToastManager.error({ message: 'main:access-insufficient-scope-error-message', title: 'main:access-error-title' });
                return 'main:access-insufficient-scope-error-message';
            }
            // ToastManager.error({ message: 'main:access-error-message', title: 'main:access-error-title' });
            return 'main:access-error-message';
        }

        if (error && error.status === 404) {
            console.log(name, 'not found error');
            // ToastManager.error({ message: 'main:not-found-error-message', title: 'main:not-found-error-title' });
            return 'main:not-found-error-message';
        }

        if (error && error.status === 500) {
            console.error(name, error);
            // ToastManager.error({ message: 'main:unhandled-exception-error-message', title: 'main:unhandled-exception-error-title'})
            return 'main:unhandled-exception-error-message';
        }

        console.error(name, error);
        return 'main:unexpected-exception-error-message';
    }
}

export const ErrorHandler = new CErrorHandler();