import { DataStorage } from './enum/dataStorage';
import Contact from './models/contact';
import Deal from './models/deal';
import Document from './models/document';
import User from './models/user';

class BXRest {
    constructor() {
        this.domain = '';
        this.cache = {};
        this.resizeTimestamp = 0;
        this.scrollSize = {};
        this.initComplete = false;
        this.placementInformation = false;
        //this.local = !false;
    }

    appCache(key, value) {
        if (typeof value === 'undefined') {
            return this.cache[key];
        }
        this.cache[key] = value;
    }

    clearCacheDocument(id) {
        this.appCache(CacheType.KeyForDocument(id), null);
    }

    clearCacheUser(id) {
        this.appCache(CacheType.KeyForUser(id), null);
    }

    installComplete() {
        const BX24 = window.BX24 || false;
        if (!BX24)
            return;

        BX24.installFinish();
    }

    getDomain() {
        return this.domain;
    }

    getLang() {
        return this.lang === 'ru' ? 'ru' : 'en';
    }

    isInitComplete() {
        return this.initComplete;
    }

    getAuth() {
        const BX24 = window.BX24 || false;
        if (!BX24)
            return;
        return BX24.getAuth();
    }

    init(callback) {
        const _ = this;
        const BX24 = window.BX24 || false;
        if (BX24) {
            BX24.init(function () {
                _.initComplete = true;
                _.domain = BX24.getDomain();
                _.lang = BX24.getLang();
                _.placementInformation = BX24.placement.info();
                callback()
            });
        }
    }

    resizeFrame() {
        const _ = this;
        const timestamp = new Date().getTime();
        this.resizeTimestamp = timestamp;
        setTimeout(function () {
            _.tryResize(timestamp);
        }, 300);
    }

    tryResize(timestamp) {
        const BX24 = window.BX24 || false;
        if (!BX24)
            return;
        const _ = this;
        if (_.resizeTimestamp === timestamp) {
            //let iframeSizes = BX24.getScrollSize();
            //let height = iframeSizes.scrollHeight;
            //if (height < 600)
            //    height = 600;
            //console.log('resize', iframeSizes.scrollWidth, height);
            //BX24.resizeWindow(_.scrollSize.scrollWidth, height);
            BX24.fitWindow();
        }
    }

    /*
    getUsers(ids, callback) {
        const _ = this;
        ids = ids.filter(x => x > 0);
        var arEntities = {};
        const arFind = [];
        ids.forEach((id) => {
            var entity = _.appCache(CacheType.KeyForUser(id));
            if (entity) {
                arEntities[id] = entity;
            } else {
                arFind.push(id);
            }
        });

        if (arFind.length < 1) {
            callback(arEntities);
            return;
        }
        this.callMethod('user.get', { 'ID': arFind }, true).then((result) => {
            result.items.forEach(item => {
                const entity = new User(item, Rest.getDomain());
                _.appCache(CacheType.KeyForUser(entity.Id), entity);
                arEntities[entity.Id] = entity;
            });
            callback(arEntities);
        });
    }*/

    getUsers(ids) {
        const _ = this;
        return new Promise((resolve, reject) => {
            ids = ids.filter(x => x > 0);
            var arEntities = {};
            const arFind = [];
            ids.forEach((id) => {
                var entity = _.appCache(CacheType.KeyForUser(id));
                if (entity) {
                    arEntities[id] = entity;
                } else {
                    arFind.push(id);
                }
            });

            if (arFind.length < 1) {
                resolve(arEntities);
                return;
            }
            _.callMethod('user.get', { 'ID': arFind }, true).then((result) => {
                result.items.forEach(item => {
                    const entity = new User(item, Rest.getDomain());
                    _.appCache(CacheType.KeyForUser(entity.Id), entity);
                    arEntities[entity.Id] = entity;
                });
                resolve(arEntities);
            }).catch(err => {
                reject(err);
            });
        });
    }

    getDealFiles(ids) {
        return new Promise((resolve) => {
            ids = ids.filter(x => x > 0);
            let arEntities = {};
            if (ids.length < 1) {
                resolve(arEntities);
                return;
            }
            this.callMethod('crm.documentgenerator.document.list', {
                filter: {
                    id: ids
                }
            }, true).then((result) => {
                result.items.forEach(item => {
                    arEntities[item.id] = item;
                });
                resolve(arEntities);
            }).catch(err => {
                console.error('', err);
                resolve(arEntities);
            });
        })
    }

    getDeals(ids) {
        return new Promise((resolve) => {
            ids = ids.filter(x => x > 0);
            const arEntities = {};
            if (ids.length < 1) {
                resolve(arEntities);
                return;
            }
            this.callMethod('crm.deal.list', {
                filter: {
                    ID: ids
                }
            }, true).then((result) => {
                result.items.forEach(item => {
                    const entity = new Deal(item, Rest.getDomain());
                    arEntities[entity.Id] = entity;
                });
                resolve(arEntities);
            }).catch(err => {
                console.error('', err);
                resolve(arEntities);
            });
        });
    }

    /*
    getContacts(ids, callback) {
        const _ = this;
        ids = ids.filter(x => x > 0);
        var arEntities = {};
        if (ids.length < 1) {
            callback(arEntities);
            return;
        }
        this.callMethod('crm.contact.list', {
            filter: {
                ID: ids
            }
        }, true).then((result) => {
            result.items.forEach(item => {
                const entity = new Contact(item, Rest.getDomain());
                arEntities[entity.Id] = entity;
            });
            callback(arEntities);
        }).catch(err => {
            console.error('', err);
            callback(arEntities);
        });
    }*/

    getContacts(ids) {
        return new Promise((resolve) => {
            ids = ids.filter(x => x > 0);
            const arEntities = {};
            if (ids.length < 1) {
                resolve(arEntities);
                return;
            }
            this.callMethod('crm.contact.list', {
                filter: {
                    ID: ids
                }
            }, true).then((result) => {
                result.items.forEach(item => {
                    const entity = new Contact(item, Rest.getDomain());
                    arEntities[entity.Id] = entity;
                });
                resolve(arEntities);
            }).catch(err => {
                console.error('getContacts', err);
                resolve(arEntities);
            });
        });
    }

    getDocuments(ids) {
        const _ = this;
        return new Promise((resolve) => {
            ids = ids.filter(x => x > 0);
            const arEntities = {};
            const arFind = [];
            ids.forEach((id) => {
                const entity = _.appCache(CacheType.KeyForDocument(id));
                if (entity) {
                    arEntities[id] = entity;
                } else {
                    arFind.push(id);
                }
            });

            if (arFind.length < 1) {
                resolve(arEntities);
                return;
            }

            this.callMethod('entity.item.get', {
                ENTITY: DataStorage.document,
                //SORT: { DATE_ACTIVE_FROM: 'ASC', ID: 'ASC' },
                FILTER: {
                    ID: arFind
                }
            }, true).then((result) => {
                result.items.forEach(item => {
                    const entity = new Document(item, Rest.getDomain());
                    //_.appCache(CacheType.KeyForDocument(entity.Id), entity);
                    arEntities[entity.Id] = entity;
                });
                resolve(arEntities);
            });
        });
    }

    callBatch(batchData, callback) {
        return new Promise((resolve, reject) => {
            const BX24 = window.BX24 || false;
            if (!BX24) {
                reject();
                return;
            }

            BX24.callBatch(batchData, function (result) {
                if (callback) {
                    callback(result);
                }
                resolve(result);
            });
        })
    }
y

    prepareResult(items, data) {
        if (Array.isArray(data)) {
            data.forEach((item) => {
                items.push(item);
            });
            return;
        }
        if (data.documents && Array.isArray(data.documents)) {
            data.documents.forEach((item) => {
                items.push(item);
            });
            return;
        }
        if (data.tasks && Array.isArray(data.tasks)) {
            data.tasks.forEach((item) => {
                items.push(item);
            });
            return;
        }
        if (data.items && Array.isArray(data.items)) {
            data.items.forEach((item) => {
                items.push(item);
            });
            return;
        }
        items.push(data);
    }

    callMethod(method, requestData = {}, all = false) {
        const _ = this;
        return new Promise((resolve, reject) => {
            const BX24 = window.BX24 || false;
            if (!BX24) {
                reject();
                return;
            }
            const items = [];
            BX24.callMethod(method, requestData, (result) => {
                if (result.error()) {
                    reject(result.error());
                }
                else {
                    const data = result.data();
                    _.prepareResult(items, data);
                    if (!all) {
                        resolve({ items: items, total: result.total() });
                    }
                    else {
                        if (result.more()) {
                            result.next();
                        }
                        else {
                            resolve({ items: items });
                        }
                    }
                }
            });
        })
    }

    scrollParentWindow(offset = 0) {
        const BX24 = window.BX24 || false;
        if (!BX24) {
            return;
        }
        BX24.scrollParentWindow(offset);
    }

    placementInfo() {
        return this.placementInformation;
    }

    removePlacementOption(key) {//todo
        delete this.placementInformation.options[key];
    }

    isAdmin () {
        const BX24 = window.BX24 || false;
        return BX24 && BX24.isAdmin()
    }

    getUserOption (key) {
        return window.BX24?.userOption?.get(key) ?? null;
    }

    setUserOption (key, val) {
        window.BX24?.userOption?.set(key, val);
    }

    getAppOption(key) {
        return window.BX24?.appOption?.get(key) ?? null;
    }

    setAppOption(key, val) {
        window.BX24?.appOption?.set(key, val);
    }
}

class CacheType {
    static KeyForUser(id) {
        return `User_${id}`;
    }

    static KeyForDocument(id) {
        return `Document_${id}`;
    }
}

export const Rest = new BXRest();