import { FilterItem } from './filter.item';
import { FilterItemProps } from './interfaces/filter.item.props';
import { FilterTypeEnum } from './enum/filter.type.enum';

export class FilterDateRangeItem extends FilterItem {
  constructor(props: Omit<FilterItemProps, 'type'>, type = FilterTypeEnum.DATETIME_RANGE) {
    super({ ...props, type });
    this.defaultValue = {
      startDate: null,
      endDate: null,
    };
  }
}
