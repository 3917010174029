import { MaskedInput } from '../MaskedInput';
import InputControl from '../InputControl';
import MaskedDynamic from 'imask/masked/dynamic';
import { IProps as ParentProps } from '../InputControl';

interface IProps extends Omit<ParentProps, 'labelKey'> {
  labelKey?: string;
}

export function PhoneControl(props: IProps) {
  return (
    <InputControl
      labelKey={props.labelKey ?? 'field.phone'}
      value={props.value}
      onChange={props.onChange}
      inputProps={{ ...props.inputProps, inputComponent: MaskedInput }}
      validators={props.validators}
      error={props.error}
      customComponentProps={{
        mask: [
          { mask: '{+}#(000) 000 00 00', startsWith: '7', definitions: { '#': /7/}, },
          { mask: '{+7}(000) 000 00 00', startsWith: '' },
        ],

        dispatch: (appended: string, dynamicMasked: MaskedDynamic) => {
          if (!!dynamicMasked.value) {
            return dynamicMasked.compiledMasks[1]
          }
          const number = (dynamicMasked.value + appended).replace(/\D/g, '');
          return dynamicMasked.compiledMasks.find((m: any) => number.indexOf(m.startsWith) === 0);
        },
      }}
    />
  );
}
