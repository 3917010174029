import { ListRequestDto } from '../models/interfaces';
import { HttpClient } from './http.client';
import { Employee } from '../models/interfaces/employee';
import { Rest } from '../rest';
import User from '../models/user';
import axios from 'axios';
import { UserRegisterDto } from '../models/dto/user';
import { ApiAuthInfo, ApiLogin } from '../models/interfaces/user';

class CUserService {
  async checkEmployee(email: string, client: string) {
    try {
      return (
        await HttpClient.post<boolean>(`/user/checkEmployee`, { email, client })).data;
    } catch (e) {
      return false;
    }
  }

  async list(data: ListRequestDto<any>) {
    return (await HttpClient.post(`/user/list`, data)).data
  }

  async getPDConsent(bxId: number, email: string) {
    try {
      return (await HttpClient.post('user/get-pd-consent', { bxId, email })).data;
    } catch (e) {
      return null;
    }
  }

  async setPDConsent(bxId: number, email: string) {
    try {
      return (await HttpClient.post('user/set-pd-consent', { bxId, email })).data;
    } catch (e) {
      return false;
    }
  }

  async checkPDConsent (currentUser: User) {
    if (Rest.getUserOption('PD') === 'true') {
      const synced = (Rest.getAppOption('PD') ?? '').split(';');
      if (!synced.includes(currentUser.id)) {
        synced.push(currentUser.id);
        await UserService.setPDConsent(currentUser.id, currentUser.EMAIL);
      }
      return false;
    }

    const consent = await UserService.getPDConsent(currentUser.id, currentUser.EMAIL);
    if (!consent) {
      return true;
    } else {
      Rest.setUserOption('PD', 'true');
    }

    return false;
  }

  async updateSignCode(email: string, signCode: string | null) {
    await HttpClient.post<boolean>(`/user/sign-code`, { email, signCode });
  }

  async details(email: string) {
    return (await HttpClient.post<Employee>(`/user/employee`, { email })).data;
  }

  async getCurrentUser() {
    try {
      return new User((await HttpClient.post(`/user/current`)).data, Rest.isAdmin());
    } catch (e) {
      console.error('Failed to get full user, getting BX only', e);
      return new User((await Rest.callMethod('user.current', {})).items?.[0] ?? null, Rest.isAdmin());
    }
  }

  async exists({ email, phone, apiKey, user }: {email?: string, phone?: string, apiKey?: string, user?: string }) {
    try {
      return (await HttpClient.post(`/user/exists`, {
        email, phone, apiKey, user
      })).data;
    } catch (e) {
      return false;
    }
  }

  async register(dto: UserRegisterDto) {
    return (await HttpClient.post<Employee>(`/user/register`, dto)).data;
  }

  async apiLogin(dto: ApiLogin) {
    return (await HttpClient.post<ApiAuthInfo>('user/login', dto)).data;
  }

  async resendEmail() {
    return (await HttpClient.post('user/resend-email')).data;
  }
}

export const UserService = new CUserService();
