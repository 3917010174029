export class FileDto {
  uuid?: string;
  name: string;
  type: string;
  content = '';


  constructor(name: string, type: string, uuid?: string) {
    this.name = name;
    this.type = type;
    this.uuid = uuid;
  }

  static async fromBlob(file: File): Promise<FileDto> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const [type, content] = (reader.result as string).split(';base64,');
        const dto = new FileDto(file.name, type.replace('data:', ''));
        dto.content = content;
        resolve(dto);
      }
      reader.readAsDataURL(file);
    });
  }
}