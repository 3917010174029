import i18n from '../i18n';
import { ROBOTS } from '../enum/robots';
import { CrmEntityType } from '../models/enum';
import { SelectItem } from '../models/interfaces';

interface RobotProperty {
  Name: string;
  Type: string;
  Options?: Record<string, string>;
  Description?: string;
}

const RobotAddingHandler = `https://${window.location.hostname}/robot`;

const getCommonData = (
  code: string,
  handler: string,
  properties: Record<string, RobotProperty>,
  returnProperties: Record<string, RobotProperty>,
  apiClients: SelectItem[],
  version: number,
) => {
  let PROPERTIES: Record<string, RobotProperty> = {};

  if (apiClients?.length) {
    PROPERTIES.client = {
      Name: i18n.t("settings.robot.property.client"),
      Type: "select",
      Options: apiClients?.reduce((acc: Record<string, string>, client) => {
        acc[client.value] = client.text;
        return acc;
      }, {}),
    };
  }

  PROPERTIES = { ...PROPERTIES, ...properties };

  if (version) {
    PROPERTIES[`version_${version}`] = {
      Name: i18n.t("settings.robot.property.version"),
      Type: "string",
      Description: i18n.t('settings.robot.property.versionDescription'),
    };
  }

  return {
    CODE: code,
    HANDLER: `${RobotAddingHandler}/${handler}`,
    AUTH_USER_ID: 1,
    NAME: i18n.t(`settings.robot.name.${code}`),
    USE_SUBSCRIPTION: "Y",
    PROPERTIES,
    RETURN_PROPERTIES: {
      ...returnProperties,
      errorCode: {
        Name: i18n.t("settings.robot.property.errorCode"),
        Type: "string",
      },
      errorMessage: {
        Name: i18n.t("settings.robot.property.errorMessage"),
        Type: "string",
      },
      apiError: {
        Name: i18n.t("settings.robot.property.apiError"),
        Type: "string",
      },
    },
  };
};

export const SEARCH_USER_ROBOT_DATA = (apiClients: SelectItem[], version = 1) => getCommonData(ROBOTS.SEARCH_USER,
  "addsearchuserAddingHandler",
  {
    userId: {
      Name: i18n.t("settings.robot.property.userId"),
      Type: "string",
    },
    [`version_1`]: {
      Name: i18n.t("settings.robot.property.version"),
      Type: "string",
      Description: i18n.t('settings.robot.property.versionDescription'),
    },
  },
  {
    employeeUuid: {
      Name: i18n.t("settings.robot.property.employeeUuid"),
      Type: "string",
    },
  }, apiClients, version);

export const SEND_TO_SIGN_ROBOT_DATA = (sps: any, apiClients: SelectItem[], version = 1) => {
  const crmTypeOptions: Record<string, string> = {};
  for (const type of Object.values(CrmEntityType)) {
    crmTypeOptions[type] = i18n.t(`crmEntity.${type}`);
  }
  for (const sp of sps) {
    crmTypeOptions[String(sp.entityTypeId)] = sp.title;
  }

  return getCommonData(ROBOTS.SEND_TO_SIGN,
    "addsenddocAddingHandler",
    {
      documentTitle: {
        Name: i18n.t("settings.robot.property.documentTitle"),
        Type: "string",
      },
      fileUrl: {
        Name: i18n.t("settings.robot.property.fileUrl"),
        Type: "string",
      },
      queue: {
        Name: i18n.t("settings.robot.property.queue"),
        Type: "text",
      },
      externalCrmType: {
        Name: i18n.t("settings.robot.property.externalCrmType"),
        Type: "select",
        Options: crmTypeOptions,
      },
      externalCrmId: {
        Name: i18n.t("settings.robot.property.externalCrmId"),
        Type: "string",
      },
      rejectForbidden: {
        Name: i18n.t("settings.robot.property.rejectForbidden"),
        Type: "select",
        Options: {
          false: i18n.t("common.no"),
          true: i18n.t("common.yes"),
        },
      },
      [`version_1`]: {
        Name: i18n.t("settings.robot.property.version"),
        Type: "string",
        Description: i18n.t('settings.robot.property.versionDescription'),
      },
    },
    {
      documentUuid: {
        Name: i18n.t("settings.robot.property.documentUuid"),
        Type: "string",
      },
      documentGuid: {
        Name: i18n.t("settings.robot.property.documentGuid"),
        Type: "string",
      },
    }, apiClients, version);
}

export const DOC_STATUS_ROBOT_DATA = (apiClients: SelectItem[], version = 1) => getCommonData(ROBOTS.DOC_STATUS,
  "addgetdocstatusAddingHandler",
  {
    documentGuid: {
      Name: i18n.t("settings.robot.property.documentGuid"),
      Type: "string",
    },
    [`version_1`]: {
      Name: i18n.t("settings.robot.property.version"),
      Type: "string",
      Description: i18n.t('settings.robot.property.versionDescription'),
    },
  },
  {
    status: {
      Name: i18n.t("settings.robot.property.status"),
      Type: "string",
    },
  }, apiClients, version);

export const SIGN_REPORT_ROBOT_DATA = (apiClients: SelectItem[], version = 1) => getCommonData(ROBOTS.SIGN_REPORT,
  "addgetsignreportAddingHandler",
  {
    documentGuid: {
      Name: i18n.t("settings.robot.property.documentGuid"),
      Type: "string",
    },
    fileType: {
      Name: i18n.t("settings.robot.property.fileType"),
      Type: "select",
      Options: {
        ORIGINAL: i18n.t("settings.robot.property.fileTypes.ORIGINAL"),
        REPORT: i18n.t("settings.robot.property.fileTypes.REPORT"),
        SIGNATURE: i18n.t("settings.robot.property.fileTypes.SIGNATURE"),
        ARCHIVE: i18n.t("settings.robot.property.fileTypes.ARCHIVE"),
        PRINT: i18n.t("settings.robot.property.fileTypes.PRINT"),
      },
    },
    signerEmployee: {
      Name: i18n.t("settings.robot.property.signerEmployee"),
      Type: "string",
    },
    [`version_1`]: {
      Name: i18n.t("settings.robot.property.version"),
      Type: "string",
      Description: i18n.t('settings.robot.property.versionDescription'),
    },
  },
  {
    name: {
      Name: i18n.t("settings.robot.property.file.title"),
      Type: "string",
    },
    link: {
      Name: i18n.t("settings.robot.property.fileUrl"),
      Type: "string",
    },
  }, apiClients, version);

export const FIND_CLIENT_ROBOT_DATA = (apiClients: SelectItem[], version = 1) => getCommonData(ROBOTS.FIND_CLIENT,
  "find-client-handler",
  {
    searchString: {
      Name: i18n.t("settings.robot.property.clientSearchTerm"),
      Type: "string",
    },
    [`version_1`]: {
      Name: i18n.t("settings.robot.property.version"),
      Type: "string",
      Description: i18n.t('settings.robot.property.versionDescription'),
    },
  },
  {
    guid: {
      Name: i18n.t("settings.robot.property.clientGuid"),
      Type: "string",
    },
  }, [], version);

export const SIGN_DOC_ROBOT_DATA = (apiClients: SelectItem[], version = 1) => getCommonData(ROBOTS.SIGN_DOC,
  "sign-document-handler",
  {
    documentGuid: {
      Name: i18n.t("settings.robot.property.documentGuid"),
      Type: "string",
    },
    signCode: {
      Name: i18n.t("settings.robot.property.signCode"),
      Type: "string",
      Description: i18n.t('settings.robot.property.signCodeDescription'),
    },
    [`version_1`]: {
      Name: i18n.t("settings.robot.property.version"),
      Type: "string",
      Description: i18n.t('settings.robot.property.versionDescription'),
    },
  },
  {
    documentGuid: {
      Name: i18n.t("settings.robot.property.documentGuid"),
      Type: "string",
    },
    status: {
      Name: i18n.t("settings.robot.property.status"),
      Type: "string",
    },
  }, apiClients, version);

export const ROBOT_DATA: Partial<Record<ROBOTS, any>> = {
  [ROBOTS.SEARCH_USER]: SEARCH_USER_ROBOT_DATA,
  [ROBOTS.SEND_TO_SIGN]: SEND_TO_SIGN_ROBOT_DATA,
  [ROBOTS.DOC_STATUS]: DOC_STATUS_ROBOT_DATA,
  [ROBOTS.SIGN_REPORT]: SIGN_REPORT_ROBOT_DATA,
  [ROBOTS.FIND_CLIENT]: FIND_CLIENT_ROBOT_DATA,
  [ROBOTS.SIGN_DOC]: SIGN_DOC_ROBOT_DATA,
}