import React from "react";
import { AppContext } from '../context/app.context';
import { DataStorage } from '../enum/dataStorage';
import { Rest } from '../rest';
import { ROBOTS } from '../enum/robots';
import { ROBOT_DATA, SEND_TO_SIGN_ROBOT_DATA } from '../service/robot.data';
import { LinearProgress } from '@mui/material';
import { Trans } from 'react-i18next';
import i18n from '../i18n';
import { SettingsEnum } from '../models/enum';

export default class UpdatePage extends React.Component {
    static contextType = AppContext;

    constructor({ currentVersion,  }) {
        super();

        const actions = {
            SettingsEntity: 5,
            FileEntity: 2,
            Event_ONAPPINSTALL: 1,
            Event_ONAPPUNINSTALL: 1,
            [`UpdateRobotVersion_${ROBOTS.SEARCH_USER}_1`]: 1,
            [`UpdateRobotVersion_${ROBOTS.SEND_TO_SIGN}_1`]: 1,
            [`UpdateRobotVersion_${ROBOTS.DOC_STATUS}_1`]: 1,
            [`UpdateRobotVersion_${ROBOTS.SIGN_REPORT}_1`]: 1,
            AddFindClientRobot: 1,
            AddSignDocumentRobot: 1,
            UpdateSignDocumentRobot: 1,
        }

        this.eventHandler = `https://${window.location.hostname}/eventhandler`;
        this.events = [
            'ONAPPINSTALL',
            'ONAPPUNINSTALL'
        ];
        this.state = {
            currentCount: 0,
            allCount: Object.values(actions).reduce((sum, steps) => sum + steps, 0),
            installFinished: false,
            currentVersion,
            updates: {
                0: 'init',
                // 1: removed
                // 2: removed
                3: 'addRobotsVersion',
                4: 'AddFindClientRobot',
                5: 'AddSignDocumentRobot',
                6: 'UpdateSignDocumentRobot'
            },
            reInstall: false,
        };
    }

    componentDidMount() {
        if (Rest.isAdmin()) {
            this.update();
        }
    }

    async update() {
        let current = Number(this.state.currentVersion) || 0;
        const reInstall = !(await Rest.callMethod('app.info'))?.items?.[0]?.INSTALLED;
        this.setState({ reInstall });

        for (let i in this.state.updates) {
            if (typeof this[this.state.updates[i]] === 'function') {
                await this[this.state.updates[i]](!reInstall && current > i);
            }
        }
    }

    async init(skip) {
        await this.createEntitySettings(skip);
        await this.createEntityFiles(skip);
        await this.bindEvents(skip);
    }

    componentDidUpdate() {
        if (this.state.installFinished) return;

        const _ = this;
        const percent = _.getPercent();
        if (percent >= 100) {
            this.setState({ installFinished: true }, () => {
                _.context.setAppSettings(SettingsEnum.Version, _.context.appVersion).then(() => {
                    if (this.state.reInstall) {
                        Rest.installComplete();
                    } else {
                        _.context.updateComplete();
                    }
                });
            })
        }
    }

    getPercent() {
        let percent;
        if (this.state.allCount < 1) {
            percent = 100;
        } else {
            percent = Math.round(this.state.currentCount / this.state.allCount * 100);
        }
        return percent;
    }

    updateProgress(steps = 1) {
        this.setState((prev) => ({ ...prev, currentCount: prev.currentCount + steps }));
    }

    async addRobotsVersion(skip) {
        if (skip) {
            this.updateProgress(4);
            return;
        }

        return this.updateRobotVersion(1, ROBOTS.SEARCH_USER, ROBOTS.DOC_STATUS, ROBOTS.SEND_TO_SIGN, ROBOTS.SIGN_REPORT);
    }

    async AddFindClientRobot(skip) {
        if (skip) {
            this.updateProgress();
            return;
        }

        try {
            await Rest.callMethod("bizproc.robot.add", ROBOT_DATA[ROBOTS.FIND_CLIENT]());
        } catch (e) {} finally {
            this.updateProgress();
        }
    }

    async AddSignDocumentRobot(skip) {
        if (skip) {
            this.updateProgress();
            return;
        }

        try {
            await Rest.callMethod("bizproc.robot.add", ROBOT_DATA[ROBOTS.SIGN_DOC](this.context.apiClients));
        } catch (e) {} finally {
            this.updateProgress();
        }
    }

    async UpdateSignDocumentRobot(skip) {
        if (skip) {
            this.updateProgress();
            return;
        }

        try {
            const existingRobots = await Rest.callMethod('bizproc.robot.list', {}, true);
            if (existingRobots.items.includes(ROBOTS.SIGN_DOC)) {
                await Rest.callMethod("bizproc.robot.update", {
                    CODE: ROBOTS.SIGN_DOC,
                    FIELDS: {
                        PROPERTIES: {
                            ...(ROBOT_DATA[ROBOTS.SIGN_DOC](this.context.apiClients)).PROPERTIES,
                        }
                    }
                });
            }
        } catch (e) {} finally {
            this.updateProgress();
        }
    }

    async updateRobotVersion(version, ...robots) {
        const existingRobots = await Rest.callMethod('bizproc.robot.list', {}, true);
        for (const CODE of robots) {
            if (!existingRobots.items.includes(CODE)) {
                this.updateProgress();
                continue;
            }

            try {
                let properties;
                switch (CODE) {
                    case ROBOTS.SEND_TO_SIGN:
                        const sps = (await Rest.callMethod('crm.type.list', {}, true))?.items?.flatMap((item) => item.types ?? []) ?? [];
                        properties = SEND_TO_SIGN_ROBOT_DATA(sps, this.context.apiClients, 0).PROPERTIES;
                        break;
                    default:
                        properties = ROBOT_DATA[CODE](this.context.apiClients, 0).PROPERTIES;
                }

                await Rest.callMethod("bizproc.robot.update", {
                    CODE,
                    FIELDS: {
                        PROPERTIES: {
                            ...properties,
                            [`version_${version}`]: {
                                Name: i18n.t("settings.robot.property.version"),
                                Type: "string",
                                Description: i18n.t('settings.robot.property.versionDescription'),
                            },
                        }
                    }
                });
            } catch (e) {} finally {
                this.updateProgress();
            }
        }
    }

    async createEntitySettings(skip) {
        if (skip) {
            this.updateProgress(2);
            return;
        }

        const storageId = DataStorage.settings;
        const propData = {
            PropSettingsValue: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'VALUE', NAME: 'value', TYPE: 'S' }]
        };
        return this.createEntity(storageId, propData);
    }

    createEntityFiles(skip) {
        if (skip) {
            this.updateProgress(5);
            return;
        }

        const storageId = DataStorage.files;

        const propData = {
            PropFileContent: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'FILE_CONTENT', NAME: 'original file', TYPE: 'F' }],
            PropFileName: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'FILE_NAME', NAME: 'original filename', TYPE: 'S' }],
            PropFileType: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'FILE_TYPE', NAME: 'original file type', TYPE: 'S' }],
            PropFileId: ['entity.item.property.add', { ENTITY: storageId, PROPERTY: 'FILE_ID', NAME: 'original file id', TYPE: 'S' }],
        };

        return this.createEntity(storageId, propData);
    }

    async bindEvents(skip) {
        if (!this.eventHandler || skip) {
            this.updateProgress(this.events.length);
            return;
        }

        try {
            const eventCopies = this.events.slice();
            const bindedEvents = await Rest.callMethod('event.get', {}, true);
            bindedEvents.items.forEach(eventItem => {
                const index = eventCopies.findIndex(x => eventItem.event === x);
                if (index > -1) {
                    eventCopies.splice(index, 1);
                    this.updateProgress();
                }
            });

            if (eventCopies.length > 0) {
                const batchData = {};
                eventCopies.forEach(x => {
                    batchData[`Event_${x}`] = ['event.bind', { event: x, handler: this.eventHandler }]
                });

                await Rest.callBatch(batchData, (batchResult) => {
                    const keys = Object.keys(batchResult);
                    keys.forEach(key => {
                        const eventResult = batchResult[key];
                        if (eventResult.error()) {
                            console.error('error-event-bind', eventResult.error());
                        }
                        else {
                            this.updateProgress();
                        }
                    });
                });
            }
        }
        catch (err) {
            console.error('event.get', err);
        }
    }

    async createEntity(storageId, properties) {
        try {
            const existing = await Rest.callMethod('entity.get', { ENTITY: storageId }, true);
            if (existing.items.length) {
                this.updateProgress(Object.keys(properties).length + 1);
                return;
            }
        } catch(e) {}

        return Rest.callMethod('entity.add', { ENTITY: storageId, NAME: storageId, ACCESS: { AU: 'X' } }).then(() => {
            return this.createEntityProperties(properties);
        }).catch((err) => {
            const error = err.ex ? err.ex : err;
            if (error.error !== 'ERROR_ENTITY_ALREADY_EXISTS') {//ERROR_ENTITY_ALREADY_EXISTS
                console.error('error-add-storage', storageId, error);
            } else {
                return this.createEntityProperties(properties);
            }
        });
    }

    async createEntityProperties(properties) {
        const result = await Rest.callBatch(properties);
        for (const key in result) {
            if (key.startsWith('Prop')) {
                const r = result[key];
                if (r.error()) {
                    const err = r.error().error ? r.error() : r.error().ex;
                    if (err.error !== 'ERROR_PROPERTY_ALREADY_EXISTS') {
                        console.error(key, err, r.error());
                    }
                }
                this.updateProgress();
            }
        }
        this.updateProgress();
    }

    render() {
        return (
          <div>
              {Rest.isAdmin() &&  <LinearProgress variant="determinate" value={this.getPercent()} />}
              {!Rest.isAdmin() && <Trans>update-not-admin</Trans>}
          </div>
        );
    }
}