import React, { useCallback, useMemo, useState } from 'react';
import { useCurrentUser } from '../../hook/useCurrentUser';
import { Box, Button, FormHelperText, Link, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ValidationContext from '../../hook/UseValidation/validation.context';
import { useTranslation } from 'react-i18next';
import InputControl from '../../component/ui/InputControl';
import { UserRegisterDto } from '../../models/dto/user';
import { concat } from '../../utils/string.utils';
import { IsEmail, IsPhone, Length, NotEmpty, StrongPassword } from '../../hook/UseValidation/validators';
import { PhoneControl } from '../../component/ui/PhoneControl';
import { PasswordControl } from '../../component/ui';
import { LoadingButton } from '@mui/lab';
import { UserRegisterValidator } from '../../service/validator/user.register.validator';
import { useErrorHandler } from '../../hook/useErrorHandler';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { UserService } from '../../service/user.service';
import { MaskedInput } from '../../component/ui/MaskedInput';
import { Rest } from '../../rest';
import { SignxClientBrief } from '../../models/interfaces/client';
import RadioControl from '../../component/ui/RadioControl';
import { keyBy } from 'lodash';
import { SelectItem } from '../../models/interfaces';

export function UserRegister() {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [validateOn, setValidateOn] = useState(false);
  const [validationError, setValidationError] = useState(-1);
  const [apiErrors, setApiErrors] = useState<Record<string, string>>({});
  const errorHandler = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();

  const [dto, setDto] = useState(new UserRegisterDto(currentUser!));
  const updateDto = (key: keyof UserRegisterDto, val: any) => setDto((prev) => ({ ...prev, [key]: val }));

  const { data: apiUser } = useQuery(["apiUser", dto.email], () => UserService.exists({ email: dto.email }), {
      onSettled: () => {
        setLoading(false);
      },
    }
  );

  const [fioEdit, setFioEdit] = useState(false);
  const [fioError, setFioError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(apiUser === undefined);
  const [registeredClients, setRegisteredClients] = useState<Record<string, SignxClientBrief>>({});
  const [client, setClient] = useState<SignxClientBrief | null>();
  const clientOptions: SelectItem[] = useMemo(() => {
    return Object.values(registeredClients).map((client) => ({
      value: client.guid,
      text: `${client.shortName} (${client.inn})`,
    }));
  }, [registeredClients]);
  const chooseClient = useCallback((guid: string | null) => {
    if (guid == null) {
      setClient(null);
      return;
    }

    const client = registeredClients[guid];
    setClient(client);
    setDto((prev) => ({
      ...prev,
      inn: client.inn,
      ogrn: client.ogrnip ?? client.ogrn ?? '',
      fullName: client.fullName,
      shortName: client.shortName,
      companyEmail: client.email,
    }));
  }, [registeredClients])

  const handleNext = async () => {
    setValidateOn(true);
    setValidationError(-1);
    setFioError('');
    if (apiUser && activeStep === 0) {
      return handleAuth();
    }
    const errors = UserRegisterValidator.validate(dto, activeStep, apiUser);
    if (errors.length === 0) {
      if (activeStep === (apiUser ? 1 : 0) && dto.phone) {
        const phoneDup = await UserService.exists({ phone: dto.phone, apiKey: dto.apiKey, user: dto.user });
        if (phoneDup) {
          setValidationError(apiUser ? 1 : 0);
          setPhoneError(t('user.register.error.phoneExists'));
          return;
        } else {
          setPhoneError('');
        }
      }

      const currentStep = activeStep;
      if (activeStep < (apiUser ? 2 : 1)) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      setValidateOn(false);
      if (currentStep >= (apiUser ? 2 : 1)) {
        return handleSend();
      }
    } else {
      if (errors.includes('lastName') ||errors.includes('firstName') || errors.includes('secondName')) {
        setFioError('user.register.error.fio');
      }
      setValidationError(activeStep + (apiUser ? 0 : 1));
    }
  }

  const handleAuth = async () => {
    try {
      const authInfo = await UserService.apiLogin({ email: currentUser!.EMAIL, password: dto.password });
      updateDto('apiKey', authInfo.authToken);
      updateDto('user', authInfo.user);
      setRegisteredClients(keyBy(authInfo.clients.filter((client) => client.type !== 'PHYSICAL'), 'guid'));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (e) {
      setValidationError(0);
      errorHandler('auth', e)
    }
  }

  const handleSend = async () => {
    setLoading(true);
    try {
      await UserService.register(dto);
      enqueueSnackbar({
        message: t('user.register.success'),
        variant: 'success',
      })
      Rest.setAppOption('register', true);
      navigate('/welcome');
    } catch (e) {
      setValidationError(2);
      setApiErrors(errorHandler('register', e) ?? {});
    } finally {
      setLoading(false);
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <ValidationContext.Provider value={{ validateOn }}>
      <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
        {apiUser && <Step>
          <StepLabel error={validationError === 0}>
            {t('user.register.step.auth')}
          </StepLabel>
        </Step>}
        <Step>
          <StepLabel error={validationError === 1}>
            {t('user.register.step.major')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel error={validationError === 2}>
            {t('user.register.step.client')}
          </StepLabel>
        </Step>
      </Stepper>
      {(() => {
        switch (activeStep + (apiUser ? 0 : 1)) {
          case 0:
            return (<Box sx={{ maxLength: '500px' }}>
              <InputControl labelKey="user.register.field.email"
                            disabled
                            value={dto.email}
                            onChange={() => false} />
              <PasswordControl labelKey="user.register.field.password"
                               required
                               value={dto.password}
                               onChange={(val) => updateDto('password', val)}
                               validators={[NotEmpty]} />
            </Box>)
          case 1:
            return (<Box sx={{ maxLength: '500px' }}>
              {!fioEdit && <InputControl labelKey="user.register.field.fullName"
                                         disabled required
                                         error={fioError}
                                         value={concat(' ', dto.lastName, dto.firstName, dto.secondName)}
                                         onChange={() => false} />}
              {fioEdit && <Box>
                <InputControl labelKey="user.register.field.lastName"
                              required
                              value={dto.lastName}
                              onChange={(val) => updateDto('lastName', val)}
                              validators={[NotEmpty, Length(255)]}/>
                <InputControl labelKey="user.register.field.firstName"
                              required
                              value={dto.firstName}
                              onChange={(val) => updateDto('firstName', val)}
                              validators={[NotEmpty, Length(255)]}/>
                <InputControl labelKey="user.register.field.secondName"
                              value={dto.secondName}
                              onChange={(val) => updateDto('secondName', val)}
                              validators={[Length(255)]}/>
              </Box>}
              <FormHelperText sx={{ mb: 1, textAlign: 'right' }}>
                <Link component="button" onClick={() => setFioEdit(!fioEdit)}>{t(`common.button.${fioEdit ? 'hide' : 'change'}`)}</Link>
              </FormHelperText>
              <InputControl labelKey="user.register.field.email"
                            disabled
                            value={dto.email}
                            onChange={() => false} />
              <PhoneControl labelKey="user.register.field.phone"
                            value={dto.phone}
                            onChange={(val) => updateDto('phone', val)}
                            validators={[IsPhone]}
                            error={phoneError} />
              {!apiUser && <PasswordControl labelKey="user.register.field.password"
                               required
                               value={dto.password}
                               onChange={(val) => updateDto('password', val)}
                               validators={[NotEmpty, StrongPassword]} />}
            </Box>);
          case 2:
            return (<Box sx={{ maxLength: '500px' }}>
              {clientOptions.length > 0 && <Box>
                <RadioControl items={clientOptions} labelKey="user.register.existingClients"
                              value={client?.guid}
                              onChange={(val) => chooseClient(String(val))}/>
                <FormHelperText sx={{ mb: 1 }}>
                  <Link component="button" onClick={() => chooseClient(null)}>{t('user.register.button.newClient')}</Link>
                </FormHelperText>
              </Box>}
              <InputControl required type="masked"
                            labelKey="user.register.field.inn"
                            value={dto.inn}
                            disabled={client?.isVerified}
                            onChange={(val) => updateDto('inn', val)}
                            inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: '000000000000' }}
                            validators={[NotEmpty, Length(12, 10)]}
                            error={apiErrors['SGNX:VLDN:CLNT:INN_REQ_ISINN']}
              />
              <InputControl required type="masked"
                            labelKey={`user.register.field.${dto.inn.length > 10 ? 'ogrnip' : 'ogrn'}`}
                            value={dto.ogrn}
                            disabled={client?.isVerified}
                            onChange={(val) => updateDto('ogrn', val)}
                            inputProps={{ inputComponent: MaskedInput }} customComponentProps={{ mask: dto.inn.length > 10 ? '000000000000000' : '0000000000000' }}
                            validators={[NotEmpty, Length(15, 13)]}
                            error={apiErrors['SGNX:VLDN:CLNT:OGRNIP_REQ_ISOGRN'] ?? apiErrors['SGNX:VLDN:CLNT:OGRN_REQ_ISOGRN']}
              />
              <InputControl labelKey="user.register.field.companyEmail"
                            required
                            value={dto.companyEmail}
                            onChange={(val) => updateDto('companyEmail', val)}
                            validators={[NotEmpty, IsEmail]}/>
              {dto.inn.length === 10 && <>
                <InputControl labelKey="user.register.field.companyFullName"
                              required
                              value={dto.fullName}
                              onChange={(val) => updateDto('fullName', val)}
                              validators={[NotEmpty]}/>
                <InputControl labelKey="user.register.field.companyShortName"
                              value={dto.shortName}
                              onChange={(val) => updateDto('shortName', val)}
                              validators={[Length(255)]}/>
              </>}
            </Box>)
        }}
      )()}
      <Stack direction="row" spacing={2} mt={2}>
        <>
          {activeStep > (!apiUser ? 0 : 1) && activeStep < 3 && <Button variant="text" color="primary" onClick={() => handleBack()}>
            {t("user.register.button.back")}
          </Button>}
        </>
        <>
          {activeStep < (apiUser ? 2 : 1) && <Button variant="outlined" color="primary" onClick={() => handleNext()} style={{ marginLeft: 'auto' }}>
            {t("user.register.button.next")}
          </Button>}
        </>
        <>
          {activeStep > (apiUser ? 1 : 0) &&
            <LoadingButton loading={loading} variant="outlined" color="success" onClick={() => handleNext()} style={{ marginLeft: 'auto' }}>
              {t("user.register.button.register")}
            </LoadingButton>}
        </>
      </Stack>
    </ValidationContext.Provider>
  )
}