export enum DocumentStatus {
    draft = "draft",
    queue = "queue",
    sendError = "sendError",
    emailComplitedDoc = "EmailComplitedDoc",
    denied = "Denied",
    cancelled = "Cancelled",
    signed = "Signed",
    awaitingSignatures = "AwaitingSignatures",
    created = "Created",
    opened = "Opened",
    completed = "Completed",
    emailToActorSended = "EmailToActorSended",
    emailToAuthorSended = "EmailToAuthorSended",
    registeredInAvisHash = "RegisteredInAvisHash"
}