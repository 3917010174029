import { useCallback, useEffect, useState } from "react";
import { AppState } from "./models/interfaces/app/app.state";
import { useQuery } from "react-query";
import { Rest } from "./rest";
import * as moment from "moment";
import i18n from "./i18n";
import { Route, Routes, useNavigate } from "react-router-dom";
import Utils from "./utils";
import { SettingsService } from "./service/settings.service";
import { FeatureFlagService } from "./service/feature.flag.service";
import { Loader } from "./component/loader";
import { AppContext } from "./context/app.context";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarProvider } from "notistack";
import { CustomAlert } from "./component/layout/CustomAlert";
import { ConfirmProvider } from "material-ui-confirm";
import { Trans } from "react-i18next";
import { Header } from "./component/header";
import DocflowPage from "./page/docflow";
import SupportPage from "./page/support";
import { UserList } from "./page/UserList";
import UserPage from "./page/user";
import LogPage from "./page/log";
import { Settings } from "./page/Settings";
import { DocumentDetails } from "./page/DocumentDetails";
import { DocumentEdit } from "./page/DocumentEdit";
import { DocumentList } from "./page/DocumentList";
import DocumentPage from "./page/document";
import { TokenSign } from "./page/TokenSign";
import { PrivateDataConsentForm } from "./page/PrivateDataConsentForm";
import { EmployeeDetails } from "./page/EmployeeDetails";
import { MainPage } from "./page/main";
import { Footer } from "./component/layout/Footer";
import { ApiKeyInfoModal } from "./component/ApiKeyInfoModal";
import UpdatePage from "./page/update";
import NotFoundRegistrationForm from "./component/notFoundRegistrationForm";
import { queryClient } from "./service/query/client";
import { theme } from "./service/theme";
import { SettingsEnum } from "./models/enum";
import { AccessKey } from "./enum/accessKey";
import { DataStorage } from "./enum/dataStorage";
import { SelectItem } from "./models/interfaces";
import { SettingsItem } from "./models/domain";
import { useCurrentUser } from "./hook/useCurrentUser";
import { ROBOTS } from "./enum/robots";
import { ROBOT_DATA, SEND_TO_SIGN_ROBOT_DATA } from "./service/robot.data";
import { UserRegister } from "./page/UserRegister";
import { Welcome } from "./page/Welcome";

const APP_VERSION = 7;

export function App() {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { data: initState } = useQuery(['init'], () => SettingsService.init(APP_VERSION, currentUser!), {
    onSuccess: (data) => {
      setAppState((prev) => ({ ...prev, ...data }));
      if (data.showPDConsent) {
        navigate('/pd-consent');
      } else if (!data.isApiKeyLegal) {
        if (!Rest.getAppOption('register')) {
          navigate('/register');
        } else {
          navigate('/welcome');
        }
      } else if (access(AccessKey.settings) && !data.apiClients?.length) {
        navigate('/settings');
      }
    },
    onSettled: () => {
      setLoading(false);
    },
    enabled: !!currentUser,
    cacheTime: Infinity,
  });
  const { data: featureFlag } = useQuery(['feature-flag'], () => FeatureFlagService.list(), {
    initialData: {},
  });


  const [appState, setAppState] = useState<AppState>({
    settings: initState?.settings ?? {} as Record<SettingsEnum, SettingsItem<any>>,
    savedSettings: initState?.savedSettings ?? {},
    needUpdate: false,
    headerVisibility: true,
    isEmployee: initState?.isEmployee ?? false,
    isApiKeyLegal: initState?.isApiKeyLegal ?? false,
    showPDConsent: initState?.showPDConsent ?? false,
    apiClients: initState?.apiClients ?? null,
    availableClients: initState?.availableClients ?? [],
    currentClient: Utils.getFromLocalStorage('client'),
    apiKeyDialogOpen: false,
  });
  const [loading, setLoading] = useState(initState === undefined);
  const [openModalNotFoundRegisteredUsers, setOpenModalNotFoundRegisteredUsers] = useState(false);

  useEffect(() => {
    Rest.init(() => {
      moment.locale(Rest.getLang());
      i18n.changeLanguage(Rest.getLang()).finally();
    })
  }, []);

  const access = useCallback((key: AccessKey) => {
    const user = currentUser || null;
    if (!user)
      return false;

    switch (key) {
      case AccessKey.settings:
        if (appState.settings && appState.settings[SettingsEnum.AdminList]?.value.includes(user.Id)) {
          return true;
        }
        return user.isAdmin;
      case AccessKey.documents:
      case AccessKey.employees:
        return appState.isApiKeyLegal && appState.isEmployee;
      default:
        return user.isAdmin;
    }
  }, [appState, currentUser]);

  const updateRobots = useCallback(async (keyUpdate: boolean, apiClients: SelectItem[]) => {
    const existingRobots: ROBOTS[] = (await Rest.callMethod('bizproc.robot.list', {}, true)).items;
    // removing existing robots
    for (const code of existingRobots) {
      try {
        await Rest.callMethod("bizproc.robot.delete", { CODE: code, });
      } catch (e) {}
    }

    // adding robots
    const robots = keyUpdate ? existingRobots : [ROBOTS.SEARCH_USER, ROBOTS.SEND_TO_SIGN, ROBOTS.DOC_STATUS, ROBOTS.SIGN_REPORT, ROBOTS.FIND_CLIENT, ROBOTS.SIGN_DOC]
    for (const CODE of robots) {
      try {
        let data;
        switch (CODE) {
          case ROBOTS.SEND_TO_SIGN:
            const sps = (await Rest.callMethod('crm.type.list', {}, true))?.items?.flatMap((item: any) => item.types ?? []) ?? [];
            data = SEND_TO_SIGN_ROBOT_DATA(sps, apiClients);
            break;
          default:
            data = ROBOT_DATA[CODE](apiClients);
        }

        await Rest.callMethod("bizproc.robot.add", data);
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  const setAppSettings = useCallback(async (key: SettingsEnum, data: any, apiClients?: SelectItem[]) => {
    const rd = {
      ENTITY: DataStorage.settings,
      NAME: key,
      ID: '' as string | undefined,
      PROPERTY_VALUES: {
        VALUE: JSON.stringify(data)
      }
    };

    const prevValue = appState.settings[key];
    setAppState((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        [key]: new SettingsItem(key, data, prevValue?.saveInSettings)
      },
    }));

    if (key === SettingsEnum.ExternalSystemClientId && apiClients !== undefined) {
      const availableClients = await SettingsService.filterOutAvailableClients(apiClients, currentUser!.EMAIL);
      Utils.saveToLocalStorage('client', availableClients[0]?.value ?? false);
      await updateRobots(appState.isApiKeyLegal, apiClients);
      setAppState((prev) => ({
        ...prev,
        apiClients: apiClients,
        availableClients: availableClients,
        isApiKeyLegal: true,
        isEmployee: availableClients.length > 0,
        currentClient: String(availableClients[0]?.value),
      }));
      await queryClient.refetchQueries('currentUser');
    }

    if (appState.savedSettings[key] !== undefined) {
      rd.ID = appState.savedSettings[key];
      await Rest.callMethod('entity.item.update', rd);
      return true;
    }
    else {
      const newId = await Rest.callMethod('entity.item.add', rd);
      setAppState((prev) => ({ ...prev, savedSettings: { ...prev.savedSettings, [key]: newId }}));
      return true;
    }
  }, [appState, currentUser, updateRobots]);

  if (loading) {
    return (
      <div className="app container-fluid my-2">
        <div className="text-center">
          <Loader size={60} />
        </div>
      </div>
    );
  }

  if (!Rest.isInitComplete()) {
    return null;
  }

  return (
    <AppContext.Provider value={{
      appVersion: APP_VERSION,
      isApiKeyLegal: appState.isApiKeyLegal,
      settings: appState.settings,
      setAppSettings: setAppSettings,
      updateComplete: () => setAppState((prev) => ({ ...prev, needUpdate: false })),
      access: access,
      setHeaderVisibility: (val) => setAppState((prev) => ({ ...prev, headerVisibility: val })),
      featureFlag: featureFlag ?? {},
      setPDConsent: () => setAppState((prev) => ({ ...prev, showPDConsent: false })),
      currentClient: appState.currentClient,
      setCurrentClient: (val) => {
        Utils.saveToLocalStorage('client', val);
        setAppState((prev) => ({ ...prev, currentClient: val }));
      },
      apiClients: appState.apiClients,
      availableClients: appState.availableClients,
      toggleApiInfoDialog: (val) => setAppState((prev) => ({ ...prev, apiKeyDialogOpen: val }))
    }}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
            <SnackbarProvider
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              Components={{
                error: CustomAlert,
              }}
            >
              <ConfirmProvider defaultOptions={{
                title: <Trans>confirm.title</Trans>,
                confirmationText: <Trans>button.ok</Trans>,
                cancellationText: <Trans>button.cancel</Trans>,
                dialogProps: { maxWidth: 'sm' }
              }}>
                  <div className="app container-fluid my-2">
                    {!loading && (appState.showPDConsent || !appState.needUpdate) &&
                      <>
                        {appState.headerVisibility &&
                          <Header apiClients={appState.availableClients} />
                        }
                        <Routes>
                          <Route path="/register" element={<UserRegister/>} />
                          <Route path="/welcome" element={<Welcome/>} />
                          <Route path="/docflow" element={<DocflowPage/>} />
                          <Route path="/support" element={<SupportPage/>} />
                          <Route path="/users" element={<UserList/>} />
                          <Route path="/user/:id" element={<UserPage/>} />
                          <Route path="/log" element={<LogPage/>} />
                          <Route path="/settings" element={<Settings/>} />
                          <Route path="/docflow/details/:guid" element={<DocumentDetails/>} />
                          <Route path="/docflowinternal/details/:guid" element={<DocumentDetails/>} />
                          <Route path="/docflowinternal/create" element={<DocumentEdit internal/>}  />
                          <Route path="/docflowinternal" element={<DocumentList internal/>}  />
                          <Route path="/document/:id" element={<DocumentPage/>} />
                          <Route path="/token-sign" element={<TokenSign/>} />
                          <Route path="/pd-consent" element={<PrivateDataConsentForm />} />
                          <Route path="/employee/:email" element={<EmployeeDetails/>} />
                          <Route path="/" element={<MainPage/>} />
                        </Routes>
                        <Footer />
                        <ApiKeyInfoModal isOpen={appState.apiKeyDialogOpen} />
                      </>
                    }
                    {!appState.showPDConsent && appState.needUpdate &&
                      <UpdatePage currentVersion={appState.settings[SettingsEnum.Version]?.value || 0} />
                    }
                    <NotFoundRegistrationForm isOpen={openModalNotFoundRegisteredUsers} onHide={() => setOpenModalNotFoundRegisteredUsers(false)} />
                  </div>
              </ConfirmProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
    </AppContext.Provider>
  );
}