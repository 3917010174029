import { SettingsEnum } from '../enum';

export class SettingsItem<T> {
  key: SettingsEnum;
  value: T | null;
  saveInSettings: boolean

  constructor(key: SettingsEnum, value: T | null, saveInSettings = true) {
    this.key = key;
    this.value = value;
    this.saveInSettings = saveInSettings;
  }
}