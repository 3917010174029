import { IMaskInput } from 'react-imask';
import { forwardRef } from 'react';
import MaskedDynamic, { DynamicMaskType } from 'imask/masked/dynamic';
import Masked from 'imask/masked/base';

interface IProps {
  mask: string | RegExp | DynamicMaskType;
  definitions?: Record<string, string>;
  onChange: (event: { target: { value: string } }) => void;
  dispatch?: (appended: string, dynamicMasked: MaskedDynamic) => Masked
  unmask?: boolean | 'typed'
}

export const MaskedInput = forwardRef<HTMLElement, IProps>(function TextMaskCustom(props, ref) {
  const { mask, definitions, onChange, ...rest } = props;
  return (
    <IMaskInput
      {...rest}
      mask={mask as any}
      unmask={props.unmask ?? true}
      definitions={definitions}
      inputRef={ref as any}
      onAccept={(value) => onChange({ target: { value } })}
      lazy={false}
    />
  );
});
