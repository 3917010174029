import IValidator from "./validators/validator";
import {useContext} from "react";
import ValidationContext from "./validation.context";
import { useTranslation } from 'react-i18next';

export const useValidation = <T> (validators: IValidator<T>[] = []) => {
   const {t} = useTranslation();
   const {validateOn, tabs, setTabs} = useContext(ValidationContext)
   return (value: T, tab: string) => {
      const resultTabs = (tabs ?? []).filter(t => t !== tab);
      let result: any = undefined;
      for (const validator of validators) {
         const error = validator.validate(value)
         if (error) {
            resultTabs.push(tab)

            if (validateOn) {
               const [message, ...params] = error
               result = t(message, params)
            }

            break
         }
      }

      if (setTabs && resultTabs.length > (tabs ?? []).length) {
         setTabs(resultTabs);
      }
      return result;
   }
}