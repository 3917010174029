import NotEmptyValidator from "./not.empty.validator";
import LengthValidator from "./length.validator";
import CapacityValidator from "./capacity.validator";
import MinMaxValidator from "./minmax.validator";
import { IsEmailValidator } from './is.email.validator';
import StrongPasswordValidator from './strong.password.validator';
import { IsNumberStringValidator } from './is.number.string.validator';
import { IsPhoneValidator } from './is.phone.validator';

export const NotEmpty = new NotEmptyValidator();
export const Length = (maxLength: number, minLength?: number) => new LengthValidator(maxLength, minLength);
export const Capacity = (maxCapacity: number, minCapacity?: number) => new CapacityValidator(maxCapacity, minCapacity);
export const MinMax = (max : number, min ?: number) => new MinMaxValidator(max , min );
export const IsEmail = new IsEmailValidator();
export const StrongPassword = new StrongPasswordValidator();
export const NumberStringValidator = new IsNumberStringValidator();
export const IsPhone = new IsPhoneValidator()