export default abstract class ModelValidator<T> {
    abstract validate(model: T, ...args: any[]): string[];

    protected checkRequired(value: any | any[], errors: string[], key = 'main') {
        if (
            !errors.includes(key) &&
            (!value || (Array.isArray(value) && !value.length) || (typeof value === 'string' && (value === 'null' || !value.trim())))
        ) {
            errors.push(key);
        }
    }

    protected checkLength(value: string | string[] | null, errors: string[], maxLength: number, key = 'main', minLength?: number) {
        if (value != null && !errors.includes(key)) {
            for (const str of Array.isArray(value) ? value : [value]) {
                if ((minLength && str?.length < minLength) || str?.length > maxLength) {
                    errors.push(key);
                }
            }
        }
    }

    protected checkCapacity(value: string | string[], errors: string[], maxCapacity: number, key = 'main', minCapacity?: number) {
        if (!errors.includes(key) && (value?.length > maxCapacity || (minCapacity && (!value?.length || value.length < minCapacity)))) {
            errors.push(key);
        }
    }

    protected check<T>(value: T | undefined, errors: string[], validator: (value: T) => boolean, key = 'main') {
        if (value !== undefined && !errors.includes(key) && !validator(value)) {
            errors.push(key);
        }
    }
}
