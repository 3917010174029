import { SettingsEnum, WebhookEventType } from '../models/enum';
import { Rest } from '../rest';
import { HttpClient } from './http.client';
import axios from 'axios';
import { SelectItem } from '../models/interfaces';
import { UserService } from './user.service';
import User from '../models/user';
import { SettingsItem } from '../models/domain';
import { DataStorage } from '../enum/dataStorage';
import { keyBy } from 'lodash';
import Utils from '../utils';
import { AppState } from '../models/interfaces/app/app.state';

const DEFAULT_SETTINGS = {
  [SettingsEnum.AdminList]: new SettingsItem<string[]>(SettingsEnum.AdminList, []),
  [SettingsEnum.ExternalSystemClientId]: new SettingsItem<string>(SettingsEnum.ExternalSystemClientId, null),
  [SettingsEnum.NotifySigner]: new SettingsItem<string>(SettingsEnum.NotifySigner, 'N'),
  [SettingsEnum.Version]: new SettingsItem(SettingsEnum.Version, 0, false),
}

class CSettingsService {

  async init(appVersion: number, currentUser: User): Promise<Partial<AppState>> {
    const result: Pick<AppState, 'needUpdate'|'showPDConsent'|'headerVisibility'|'settings'|'savedSettings'|'isApiKeyLegal'|'apiClients'|'availableClients'|'isEmployee'|'currentClient'> = {
      needUpdate: false,
      headerVisibility: true,
      showPDConsent: false,
      settings: {} as Record<SettingsEnum, SettingsItem<any>>,
      savedSettings: {},
      isApiKeyLegal: false,
      apiClients: null,
      availableClients: [],
      isEmployee : false,
      currentClient: null,
    }

    if ((await HttpClient.post('/domain/auth')).data.domainAuth === false) {
      result.needUpdate = true;
      return result;
    }

    if (await UserService.checkPDConsent(currentUser)) {
      result.showPDConsent = true;
      result.headerVisibility = false;
    }
    const settingsItems = await Rest.callMethod('entity.item.get', { ENTITY: DataStorage.settings }, true);
    const dataMap = keyBy(settingsItems.items, 'NAME');
    for (const key in DEFAULT_SETTINGS) {
      const storedItem = dataMap[key];
      const defaultItem = DEFAULT_SETTINGS[key as SettingsEnum];
      result.settings[key as SettingsEnum] = new SettingsItem(key as SettingsEnum, Utils.tryParseJson(storedItem?.PROPERTY_VALUES?.VALUE) || defaultItem.value, defaultItem.saveInSettings);
      if (storedItem?.ID) {
        result.savedSettings[key as SettingsEnum] = storedItem.ID;
      }
    }

    result.needUpdate = result.settings?.[SettingsEnum.Version]?.value < appVersion;
    if (result.settings[SettingsEnum.ExternalSystemClientId] !== undefined) {
      result.apiClients = await SettingsService.checkApikeyAuth(result.settings[SettingsEnum.ExternalSystemClientId].value);
      result.isApiKeyLegal = result.apiClients != null;
    }

    if (result.apiClients != null) {
      result.availableClients = await this.filterOutAvailableClients(result.apiClients, currentUser.EMAIL);
      let chosen = Utils.getFromLocalStorage('client');
      if (!chosen || !result.availableClients.some((c) => c.value === chosen)) {
        chosen = result.availableClients[0]?.value ?? false;
        Utils.saveToLocalStorage('client', chosen);
      }
      result.isEmployee = result.availableClients.length > 0;
      result.currentClient = chosen;
    }

    return result;
  }

  async toggleWebhook(registerWebHook: boolean, eventType: WebhookEventType) {
    return HttpClient.post(`/webhook/${registerWebHook ? 'register' : 'remove'}`, { eventType });
  }

  async checkApikeyAuth(clientToken: string): Promise<SelectItem[] | null> {
    const currentUserAuthData = Rest.getAuth();
    if (!currentUserAuthData)
      return null;

    try {
      return (await axios.post<SelectItem[]>('/apikeycheck', { clientToken, ...currentUserAuthData })).data;
    }
    catch (err) {
      console.error('error checkApikeyAuth', err);
      return null;
    }
  }

  async updateApiKey(clientToken: string): Promise<SelectItem[] | null> {
    const currentUserAuthData = Rest.getAuth();
    if (!currentUserAuthData)
      return null;

    try {
      return (await axios.post<SelectItem[]>('/apikeyupdate', { clientToken, ...currentUserAuthData })).data;
    }
    catch (err) {
      console.error('error checkApikeyAuth', err);
      return null;
    }
  }

  async filterOutAvailableClients(apiClient: SelectItem[] | null, email: string) {
    if (apiClient == null) return [];

    const available = [];
    for (const client of apiClient) {
      if (await UserService.checkEmployee(email, String(client.value))) {
        available.push(client);
      }
    }
    return available;
  }
}
export const SettingsService = new CSettingsService();
